import "../../App.css";
import DownloadIcon from "@mui/icons-material/Download";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import { NavLink } from "react-router-dom";

export default function About() {
  const getDate = () => {
    var dob = new Date("01/21/2024");
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1992);
    return age;
  };

  const scroll = (section) => {
    if (section === "#") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div
      id="about"
      className="min-h-screen bg-white flex flex-col text-center gap-5 text-black text-sm lg:text-lg  font-normal"
    >
      <div
        className="head text-3xl lg:text-5xl mt-12 font-bold text-yellow-600"
        data-aos={"slide-down"}
      >
        HAKKIMDA
      </div>
      <div className="flex flex-row  gap-6 ml-8">
        <div className="flex-col mt-14 flex flex-auto w-64 gap-5">
          <h3
            className="text-xl lg:text-3xl font-medium"
            data-aos={"fade-left"}
          >
            Ben <span className="text-yellow-600">Eren ÇOLAK</span> Full-stack
            yazılım geliştiricisiyim
          </h3>
          <p
            className="pt-5 leading-7 text-slate-900 text-justify"
            data-aos={"fade-left"}
          >
            Teknolojinin hızla gelişen dünyasına olan ilgim beni yazılım alanına
            yönlendirdi. Yenilikçi düşünme yeteneğim ve problem çözme
            becerilerim, yazılım dünyasında fark yaratma isteğimle birleşerek,
            yeni projelerde etkili bir şekilde çalışabileceğimi gösteriyor. Bu
            alanda gelişimime etkili olacağına inandığım{" "}
            <strong>
              Bilge Adam Boost Yıldız Yazılımcı Yetiştirme Programına{" "}
            </strong>
            dahil oldum. Bundan sonraki süreçte, hem kişisel hem de mesleki
            büyümeyi hedefliyorum.
          </p>
          <p
            className="text-justify leading-7 text-slate-900"
            data-aos={"fade-right"}
          >
            <strong>
              BilgeAdam BOOST Yıldız Yazılımcı Yetiştime Programı,
            </strong>
            6 ay süren ve hafta içi 5 gün tam zamanlı olarak düzenlenen bir
            mesleki eğitim projesidir. Program, eğitim süresi boyunca güncel
            teknolojilere dayalı teorik ve ağırlıklı olarak pratik dersler
            sunmaktadır. Bu süre zarfında, C#, ASP.NET Core MVC, RESTful API,
            MSSql vb. birçok teknolojiyi kullanarak proje geliştirme deneyimi
            kazanma fırsatı buldum. Uygulama odaklı eğitim, öğrencilere gerçek
            dünya projelerinde aktif olarak yer alma ve bu teknolojileri etkili
            bir şekilde kullanabilme becerisi kazanma imkanı sunmaktadır.
          </p>
          <p
            className="text-justify leading-7 text-slate-900"
            data-aos={"fade-left"}
          >
            Şu anda aktif olarak iş arıyorum. Aynı zamanda freelancer olarak
            işler yapmaktayım. Eğer özgemişimi firmanız için uygun gördüyseniz
            benimle{" "}
            <NavLink
              href="#contact"
              onClick={() => scroll("contact")}
              title="Contact"
              style={{ textDecoration: "underline", color: "blue" }}
            >
              iletişime
            </NavLink>{" "}
            geçebilirsiniz.
          </p>
        </div>

        <div
          class="relative flex-auto w-32   sm:rounded-lg  pl-4"
          data-aos={"slide-left"}
        >
          {/* <img
            src={LearningCode}
            alt="Learning Code"
            className="motion-safe:animate-zoomy"
          /> */}
          <div className="motion-safe:animate-zoomy">
            <lottie-player
              autoplay
              loop
              mode="bounce"
              src="https://assets10.lottiefiles.com/packages/lf20_w98qte06.json"
              style={{ width: "350px" }}
            />
          </div>

          <a
            href={require("../../assets/files/ErenÇOLAK CV.pdf")}
            download={"ErenÇOLAK_CV"}
            target="_blank"
            rel="noreferrer"
            className="py-1 md:py-2 lg:py-3 mt-3 lg:mt-5 text-white mx-auto px-4  lg:px-9 bg-blue-600 border-2 w-fit border-blue-500 rounded-3xl  hover:-translate-y-1.5 duration-[350ms] hover:duration-[350ms] hover:bg-blue-800 hover:scale-[1.023] focus:bg-blue-800 sm:text-xl  animate-zoomy"
          >
            CV İNDİR
            <DownloadIcon />
          </a>
        </div>
      </div>
    </div>
  );
}
