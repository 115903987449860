export const projects = [
  {
    title:
      "AspNet Core 6.0 Api İle Gerçek Zamanlı ( SignalR ) QR Kodlu Sipariş Yönetimi",
    desc: "Restoran Sipariş Yönetimi Web Sitesi, SignalR teknolojisiyle sipariş ve personel yönetimini anlık ve etkili bir şekilde sunan, müşteri etkileşimini artıran modern bir çözümdür. SOLID prensiplerine uygun olarak geliştirilen platform, güvenilir ve ölçeklenebilir bir performans sağlar.",
    tags: [
      {
        name: "Asp.Net Core Api",
        color: "red-600",
      },
      {
        name: "Asp.Net Core  6.0 MVC",
        color: "gray-600",
      },
      {
        name: "SignalR",
        color: "yellow-600",
      },
      {
        name: "MSSQL",
        color: "orange-600",
      },
      {
        name: "N Tier Architecture",
        color: "green-600",
      },
      {
        name: "MailKit,",
        color: "purple-600",
      },
      {
        name: "HTML-JavaScript-CSS",
        color: "red-600",
      },
      {
        name: "Entity Framework",
        color: "blue-600",
      },
      {
        name: "Identity",
        color: "red-600",
      },
      {
        name: "AutoMapper",
        color: "green-600",
      },
      {
        name: "Ajax",
        color: "blue-600",
      },
      {
        name: "Alert",
        color: "orange-600",
      },
      {
        name: "Bootstrap",
        color: "blue-600",
      },
      {
        name: "OOP",
        color: "white-600",
      },
    ],
    img: "signalR.jpg",
    code: "https://github.com/ErenColk/SignalRProject",
  },
  {
    title: "BAExamApp Sınav Yönetim Sistemi",
    desc: "Uygulama, kullanıcı hesap yönetimi, menü güncellemeleri, sipariş oluşturma ve yönetme gibi temel özellikleri içermektedir. Ayrıca, güvenli kimlik doğrulama ve yetkilendirme için .NET Core Identity kullanılmıştır.",
    tags: [
      {
        name: "Asp.Net Core  6.0 MVC",
        color: "red-600",
      },
      {
        name: "Entity Framework Core",
        color: "green-600",
      },
      {
        name: "RabbitMQ/Hangfire",
        color: "yellow-600",
      },
      {
        name: "MSSQL",
        color: "orange-600",
      },
      {
        name: "OOP",
        color: "green-600",
      },
      {
        name: "Identity",
        color: "blue-600",
      },
      {
        name: "HTML-JavaScript-CSS",
        color: "orange-600",
      },
      {
        name: "Automapper",
        color: "green-600",
      },
      {
        name: "Mapster",
        color: "blue-600",
      },
      {
        name: "ImageSharp,",
        color: "yellow-600",
      },
      {
        name: "ToastNotification",
        color: "green-600",
      },
      {
        name: "ExcelDataReader",
        color: "gray-600",
      },
      {
        name: "FluentValidation",
        color: "red-600",
      },
    ],
    img: "baExam.png",
    link: "https://exam.bilgeadam.com/Login",
    code: "https://github.com/ErenColk/BAExamApp",
  },
  {
    title: "BoostBurger Sipariş Yönetim",
    desc: "Blog Yönetim Sistemi, üyelerin yazarları takip edip makaleleri okuyabildiği, yazarların ise makalelerini paylaşabildiği bir ASP.NET MVC Core uygulamasıdır. Sistem, ayrıca yöneticiler için özel bir Admin Paneli sunar.",
    tags: [
      {
        name: "Asp.Net Core 6.0 MVC",
        color: "red-600",
      },
      {
        name: "Entity Framework Core",
        color: "green-600",
      },
      {
        name: "MSSQL",
        color: "orange-600",
      },
      {
        name: "OOP",
        color: "green-600",
      },
      {
        name: "Identity",
        color: "blue-600",
      },
      {
        name: "HTML-JavaScript-CSS",
        color: "orange-600",
      },
      {
        name: "Automapper",
        color: "green-600",
      },
      {
        name: "AJAX",
        color: "blue-600",
      },
      {
        name: "ImageSharp,",
        color: "yellow-600",
      },
      {
        name: "ToastNotification",
        color: "green-600",
      },
      {
        name: "FluentValidation",
        color: "red-600",
      },
    ],
    img: "boostburger.jpg",
    code: "https://github.com/ErenColk/FoodServiceWebApp",
  },
  {
    title: "EKİTE İnsan Kaynakları",
    desc: "Ekite, React.js ve ASP.NET Web API kullanılarak geliştirilmiş bir insan kaynakları yönetim sistemidir. Çalışanların izin, avans ve harcama taleplerini kolayca iletmelerini ve yöneticilerin bu talepleri onaylayıp yönetmesini sağlar, ayrıca site yöneticileri farklı şirketleri görüntüleyebilir ve işlemler gerçekleştirebilirler.",
    tags: [
      {
        name: "Asp.Net Web API (RESTful API)",
        color: "red-600",
      },
      {
        name: "OOP",
        color: "green-600",
      },
      {
        name: "React.JS",
        color: "red-600",
      },
      {
        name: "JSON WEB TOKENS( JWT ),",
        color: "red-600",
      },
      {
        name: "ImageSharp",
        color: "yellow-600",
      },
      {
        name: "AJAX",
        color: "blue-600",
      },
      {
        name: "Asp.Net Core 6.0 MVC",
        color: "red-600",
      },
      {
        name: "Entity Framework Core",
        color: "green-600",
      },
      {
        name: "MSSQL",
        color: "orange-600",
      },
      {
        name: "Onion Architecture (Soğan Mimarisi)",
        color: "orange-600",
      },
      {
        name: "Identity",
        color: "blue-600",
      },
      {
        name: "HTML-JavaScript-CSS",
        color: "orange-600",
      },
      {
        name: "Automapper",
        color: "green-600",
      },
      {
        name: "ToastNotification",
        color: "green-600",
      },
      {
        name: "FluentValidation",
        color: "red-600",
      },
    ],
    img: "ekite.png",
    link: "https://ekite.azurewebsites.net",
    code: "https://github.com/ErenColk/EkiteProject",
  },

  {
    title: "JINJI Alışveriş Sitesi",
    desc: "ASP.NET MVC .NET 6 tabanlı çok katmanlı mimariye sahip projemiz, işletmelere ve kullanıcılara etkileyici ve güvenli bir çevrimiçi alışveriş deneyimi sunar. Admin Paneli ile ürünleri yönetebilir, içerikleri denetleyebilir ve site görünümünde değişiklik yapabilirsiniz.",
    tags: [
      {
        name: "Asp.Net Core 6.0 MVC",
        color: "red-600",
      },
      {
        name: "Entity Framework Core",
        color: "green-600",
      },
      {
        name: "MSSQL",
        color: "orange-600",
      },
      {
        name: "OOP",
        color: "green-600",
      },
      {
        name: "Identity",
        color: "blue-600",
      },
      {
        name: "HTML-JavaScript-CSS",
        color: "orange-600",
      },
      {
        name: "Automapper",
        color: "green-600",
      },
      {
        name: "AJAX",
        color: "blue-600",
      },
      {
        name: "ImageSharp,",
        color: "yellow-600",
      },
      {
        name: "ToastNotification",
        color: "green-600",
      },
      {
        name: "FluentValidation",
        color: "red-600",
      },
    ],
    img: "jinji.jpg",
    link: "https://jinji.com.tr/",
    code: "https://github.com/ErenColk/EkiteProject",
  },
  {
    title: "BLOGVİSTA Blog Sitesi",
    desc: "ASP.NET MVC Core tabanlı Blog Yönetim Sistemi, kullanıcılara üye ve yazar olarak katılma imkanı sunar. Üyeler, yazarları takip edebilir ve makaleleri okurken, yazarlar kendi içeriklerini oluşturabilir ve paylaşabilirler; ayrıca sistem yöneticileri için özel bir Admin Paneli bulunmaktadır.",
    tags: [
      {
        name: "Asp.Net Core 6.0 MVC",
        color: "red-600",
      },
      {
        name: "Entity Framework Core",
        color: "green-600",
      },
      {
        name: "MSSQL",
        color: "orange-600",
      },
      {
        name: "OOP",
        color: "green-600",
      },
      {
        name: "Identity",
        color: "blue-600",
      },
      {
        name: "HTML-JavaScript-CSS",
        color: "orange-600",
      },
      {
        name: "Automapper",
        color: "green-600",
      },
      {
        name: "AJAX",
        color: "blue-600",
      },
      {
        name: "ImageSharp,",
        color: "yellow-600",
      },
      {
        name: "Modals",
        color: "yellow-600",
      },
      {
        name: "ToastNotification",
        color: "green-600",
      },
      {
        name: "FluentValidation",
        color: "red-600",
      },
      {
        name: "SummerNote",
        color: "red-600",
      },
    ],
    img: "blogvista.jpg",
    code: "https://github.com/ErenColk/BlogProjectOnion",
  },
  {
    title: "Alışveriş Sitesi React.JS",
    desc: "Bu proje React.JS ile geliştirilmiş bir Web uygulamasıdır. Ürünleri listeleyip sepete eklemeye olanak sağlayan ve filtrelebildiğimiz bir alışveriş yönetim sistemidir.",
    tags: [
      {
        name: "React.JS",
        color: "red-600",
      },
    ],
    img: "shopping.png",
    code: "https://github.com/ErenColk/Shopping-app",
  },
  {
    title: "CalorieWiz Masaüstü Uygulaması ",
    desc: "Uygulamada kullanıcılara günlük kalori alımlarını izlemelerine ve hesaplamalarını sağladım.Kullanıcılar, yedikleri yiyeceklerin veya içtikleri içeceklerin kalori değerlerini girebilirler.Uygulama, bu girdileri kullanarak toplam günlük kalori alımını hesaplar ve kullanıcılara sonuçları gösterir.",
    tags: [
      {
        name: "Microsoft Visual Studio IDE",
        color: "red-600",
      },
      {
        name: ".NET Core / C#",
        color: "blue-600",
      },
      {
        name: "Entity Framework Core",
        color: "gray-600",
      },
      {
        name: "Identity",
        color: "orange-600",
      },
      {
        name: "MSSQL-TSQL",
        color: "green-600",
      },
      {
        name: "OOP",
        color: "yellow-600",
      },
      {
        name: "Katmanlı mimari (n-tired architecture)",
        color: "red-600",
      },
    ],
    img: "caloriwiz.png",
    code: "https://github.com/ErenColk/CalorieTrackingApp",
  },
];
