import BarGraph from "./BarGraph";
import DownloadIcon from "@mui/icons-material/Download";

export default function Resume() {
  return (
    <div id="resume" className="min-h-screen flex-col text-center">
      <div
        className="text-5xl font-bold ml-8 py-8 text-yellow-600"
        data-aos={"slide-down"}
      >
        ÖZGEÇMİŞ
      </div>

      <ol class="relative border-l  border-gray-700 ml-9 mb-6">
        <div className="text-3xl font-bold  py-3 mb-5">Deneyimler</div>

        <li class="mb-10" data-aos="zoom-in">
          <span class="absolute flex items-center justify-center w-6 h-6   rounded-full -left-3 ring-8  ring-gray-900 bg-blue-900">
            <svg
              aria-hidden="true"
              class="w-3 h-3  text-blue-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
          <h3 class="mb-1 ml-10 text-lg font-semibold text-white text-start">
            Second Level Support Specialist - Sodexo Avantaj ve Ödüllendirme
            Hizmetleri A.Ş.
          </h3>
          <time class="block pt-1 pb-3  ml-10  text-sm font-normal leading-none text-start text-gray-500">
            Temmuz / 2024 - Devam Ediyor...
          </time>
        </li>

        <li class="mb-10" data-aos="zoom-in">
          <span class="absolute flex items-center justify-center w-6 h-6   rounded-full -left-3 ring-8  ring-gray-900 bg-blue-900">
            <svg
              aria-hidden="true"
              class="w-3 h-3  text-blue-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
          <h3 class="mb-1 ml-10 text-lg font-semibold text-white text-start">
            YAZILIM GELİŞTİRİCİ - STAJYER - BilgeAdam Akademi
          </h3>
          <time class="block pt-1 pb-3  ml-10  text-sm font-normal leading-none text-start text-gray-500">
            Ocak / 2024 - Mart / 2024
          </time>
          <ul className="text-start ml-10 p-0 ">
            <li>
              <p class="mb-1 text-base font-normal text-gray-400">
                • Kod analizi yaparak hataları belirleyip iyileştirme fırsatları
                tespit ettim.
              </p>
            </li>
            <li>
              <p class="mb-1 text-base font-normal text-gray-400">
                • Takım içinde aktif rol alarak hedeflere ulaşmaya katkı
                sağladım.
              </p>
            </li>
            <li>
              <p class="mb-1 text-base font-normal text-gray-400">
                • Projede çeşitli alanlarda katkılarım oldu, ancak özellikle
                önemli rollerim arasında şunlar yer alıyor; projenin akışını
                hızlandırmak için triggerlar yazmak, gönderilen tüm maillerin
                kaydedilmesini sağlamak, maillerin gönderilip gönderilmediğini
                tespit edip veri tabanında tutmak ve tüm sayfa geçişlerine
                animasyonlar eklemek (loader).
              </p>
            </li>
          </ul>
        </li>
        <li class="mb-10" data-aos="zoom-in">
          <span class="absolute flex items-center justify-center w-6 h-6   rounded-full -left-3 ring-8  ring-gray-900 bg-blue-900">
            <svg
              aria-hidden="true"
              class="w-3 h-3  text-blue-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
          <h3 class="mb-1 ml-10 text-lg font-semibold text-white text-start">
            MÜŞTERİ HİZMETLERİ - Site Telekom Müşteri İletişim Merkezi
          </h3>
          <time class="block pt-1 pb-3  ml-10  text-sm font-normal leading-none text-start text-gray-500">
            Mayıs / 2022 - Aralık / 2022
          </time>
          <ul className="text-start ml-10 p-0 ">
            <li>
              <p class="mb-1 text-base font-normal text-gray-400">
                • Hizmet kalitesini standartlara uygun bir şekilde sağlamak için
                çağrıları düzenli olarak izlemek
              </p>
            </li>
            <li>
              <p class="mb-1 text-base font-normal text-gray-400">
                • Çağrı merkezi temsilcilerinin performansını periyodik olarak
                değerlendirmek
              </p>
            </li>
            <li>
              <p class="mb-1 text-base font-normal text-gray-400">
                • Ekip üyeleri arasında güvenli ve uyumlu bir çalışma ortamı
                oluşturmak
              </p>
            </li>
          </ul>
        </li>
        <li class="mb-10" data-aos="zoom-in">
          <span class="absolute flex items-center justify-center w-6 h-6   rounded-full -left-3 ring-8  ring-gray-900 bg-blue-900">
            <svg
              aria-hidden="true"
              class="w-3 h-3  text-blue-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
          <h3 class="mb-1 ml-10 text-lg font-semibold text-white text-start">
            PEYZAJ MİMARI - Eskişehir Muharip Hava Kuvvetleri
          </h3>
          <time class="block pt-1 pb-3  ml-10  text-sm font-normal leading-none text-start text-gray-500">
            Ağustos / 2020 - Ağustos / 2021
          </time>
          <ul className="text-start ml-10 p-0 ">
            <li>
              <p class="mb-1 text-base font-normal text-gray-400">
                • Şartname , Keşif ve Metraj hazırlama
              </p>
            </li>
            <li>
              <p class="mb-1 text-base font-normal text-gray-400">
                • Yapısal ve bitkisel peyzajın yönetimi, denetimi ve
                uygulanmasını sağlamak
              </p>
            </li>
            <li>
              <p class="mb-1 text-base font-normal text-gray-400">
                • 180.000 m² alanın süs bitkisi şartnamesinin hazırlanması ,
                bitki temini ve yapısal malzemelerin planlanması
              </p>
            </li>
          </ul>
        </li>
        <li class="mb-10" data-aos="zoom-in">
          <span class="absolute flex items-center justify-center w-6 h-6   rounded-full -left-3 ring-8  ring-gray-900 bg-blue-900">
            <svg
              aria-hidden="true"
              class="w-3 h-3  text-blue-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
          <h3 class="mb-1 ml-10 text-lg font-semibold text-white text-start">
            PEYZAJ MİMARI - ESC Mühendislik - Yarı Zamanlı
          </h3>
          <time class="block pt-1 pb-3  ml-10  text-sm font-normal leading-none text-start text-gray-500">
            Haziran / 2018 - Nisan / 2019
          </time>
          <ul className="text-start ml-10 p-0 ">
            <li>
              <p class="mb-1 text-base font-normal text-gray-400">
                • Trafik ışıkları ve levhaları, yön tabelaları, kent
                mobilyaları, aydınlatma lambaları, rögar kapakları, güneş
                panelleri, ağaçlar vb. Envanter bilgilerinin toplanmasında,
                projenin amacına uygun olarak, mobil haritalama sistemlerinden
                gelen LiDAR nokta bulutu verileri, yüksek çözünürlüklü 360°
                panoramik fotoğraflar ve hava fotoğrafları ile verilerin
                toplanması
              </p>
            </li>
          </ul>
        </li>
        <li class="mb-10" data-aos="zoom-in">
          <span class="absolute flex items-center justify-center w-6 h-6   rounded-full -left-3 ring-8  ring-gray-900 bg-blue-900">
            <svg
              aria-hidden="true"
              class="w-3 h-3  text-blue-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
          <h3 class="mb-1 ml-10 text-lg font-semibold text-white text-start">
            PEYZAJ MİMARI - STAJYER - Adana Büyükşehir Belediyesi
          </h3>
          <time class="block pt-1 pb-3  ml-10  text-sm font-normal leading-none text-start text-gray-500">
            Temmuz - Ağustos / 2016
          </time>
        </li>
      </ol>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-7 mt-7">
        <ol class="relative border-l  border-gray-700 ml-9 mb-6">
          <div className="text-3xl font-bold py-3 mb-10">SERTİFİKALAR</div>
          <li class="mb-10" data-aos="zoom-in">
            <span class="absolute flex items-center justify-center w-6 h-6   rounded-full -left-3 ring-8  ring-gray-900 bg-blue-900">
              <svg
                aria-hidden="true"
                class="w-3 h-3  text-blue-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
            <h3 class="mb-1 text-lg font-semibold text-white">
              RabbitMQ - Mesaj Kuyruk Sistemi
            </h3>
            <time class="block pt-1 pb-3  text-sm font-normal leading-none  text-gray-500">
              19 Mayıs 2024'de tamamlandı.
            </time>
            <p class="mb-4 text-base font-normal  text-gray-400">
              RabbitMQ bir mesaj kuyruk sistemidir. Request-Response sürelerinin
              uzun olduğu işlemlerde, uygulamanın iş yükünü hafifleterek daha
              iyi bir kullanıcı deneyimi sunmaktadır.
            </p>
            <a
              href="https://www.udemy.com/certificate/UC-12e4b7b6-2c34-4a9d-8b7b-1d8dfc20d946/"
              target="_blank"
              rel="noreferrer"
              class="inline-flex items-center px-4 py-2 mt-2 text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                  clip-rule="evenodd"
                ></path>
              </svg>{" "}
              Sertifikayı İndir
            </a>
          </li>
          <li class="mb-10" data-aos="zoom-out" data-aos-delay="700">
            <span class="absolute flex items-center justify-center w-6 h-6 rounded-full -left-3 ring-8 ring-gray-900 bg-blue-900">
              <svg
                aria-hidden="true"
                class="w-3 h-3 text-blue-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
            <h3 class="mb-1 text-lg font-semibold text-white">
              AspNet Core 6.0 Api | SignalR
            </h3>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-500">
              7 Mayıs 2024'de tamamlandı
            </time>
            <p class="text-base font-normal text-gray-400 ">
              ASP.NET Core 6.0 API ve SignalR ile geliştirilen QR kodlu sipariş
              yönetimi projesi, müşterilere hızlı ve etkili bir sipariş deneyimi
              sunuyor. SignalR sayesinde gerçek zamanlı iletişim sağlanarak,
              kullanıcılar siparişlerini kolayca takip edip yönetebiliyorlar.
            </p>
            <a
              href="https://www.udemy.com/certificate/UC-f3bd4465-5e79-4074-b69d-e10f810061df/"
              target="_blank"
              rel="noreferrer"
              class="inline-flex items-center px-4 py-2 mt-5 text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                  clip-rule="evenodd"
                ></path>
              </svg>{" "}
              Sertifikayı İndir
            </a>
          </li>
          <li data-aos="zoom-in" data-aos-offset={"-15"} data-aos-delay="1300">
            <span class="absolute flex items-center justify-center w-6 h-6 rounded-full -left-3 ring-8 ring-gray-900 bg-blue-900">
              <svg
                aria-hidden="true"
                class="w-3 h-3 text-blue-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
            <h3 class="mb-1 text-lg font-semibold text-white">
              .NET Core | C#
            </h3>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-500">
              5 Kasım 2023'te tamamlandı
            </time>
            <p class="text-base font-normal text-gray-400">
              C# Microsoft'un geliştirmiş olduğu yeni nesil bir programlama
              dilidir ve yine Microsoft tarafından geliştirilmiş olan .Net
              teknolojisi için geliştirilmiş dillerden biridir.
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.udemy.com/certificate/UC-ab94c357-8d90-44e3-8a0f-f1c4c12858b3/"
              class="inline-flex items-center px-4 py-2 mt-5 text-sm font-medium border rounded-lg focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
            >
              <svg
                class="w-4 h-4 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                  clip-rule="evenodd"
                ></path>
              </svg>{" "}
              Sertifikayı İndir
            </a>
          </li>
        </ol>

        <div className="flex flex-col text-center" data-aos="slide-up">
          <div className="text-3xl font-bold py-3">YETENEKLERİM</div>
          <div className="ml-16 flex flex-col gap-8 mt-6">
            <BarGraph name={".NET Core"} percent={90} />
            <BarGraph name={"C#"} percent={90} />
            <BarGraph name={"Reacj.JS"} percent={85} />
            <BarGraph name={"OOP"} percent={95} />
            <BarGraph name={"SOLID"} percent={90} />
            <BarGraph name={"MSSQL,T-SQL"} percent={85} />
            <BarGraph name={"HTML/CSS"} percent={90} />
            <BarGraph name={"JavaScript"} percent={90} />
            <BarGraph name={"ASP.NET CORE MVC"} percent={95} />
            <BarGraph name={"Web API (RESTful API)"} percent={95} />
            <BarGraph name={"Agile-Scrum"} percent={85} />
            <BarGraph name={"RabbitMQ"} percent={90} />
            <BarGraph name={"SignalR"} percent={85} />
          </div>

          <a
            href={require("../../assets/files/ErenÇOLAK CV.pdf")}
            download={"ErenÇOLAK CV"}
            class="inline-flex items-center px-6 py-2 mt-12 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
          >
            CV İNDİR <DownloadIcon />
          </a>
        </div>
      </div>
    </div>
  );
}
